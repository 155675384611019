.users-container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.users-container:last-child {
  margin-bottom: 16px;
}

.users-segment {
  margin-top: 15px !important;
  height: 78px !important;
  display: flex;
}

.users-segment-icon {
  padding-top: 10px;
  padding-right: .5em;
}

.users-input {
  width: 100%;
}

.users-user {
  margin-top: 15px !important;
  min-height: 78px;
}

.users-picture-placeholder {
  text-align: center;
  padding-top: 10px !important;
  opacity: .125;
}

.ui.items > .item > .content > .description {
  white-space: pre-wrap;
  margin-top: 15px;
}