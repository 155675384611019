/*******************************
         Site Overrides
*******************************/

.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="time"],
.ui.form input[type="text"],
.ui.form input[type="file"],
.ui.form input[type="url"],
.ui.form textarea {
    color: var(--smui-site-input-color, @inputColor);
}

.ui.form input:not([type]):focus, 
.ui.form input[type="date"]:focus, 
.ui.form input[type="datetime-local"]:focus, 
.ui.form input[type="email"]:focus, 
.ui.form input[type="number"]:focus, 
.ui.form input[type="password"]:focus, 
.ui.form input[type="search"]:focus, 
.ui.form input[type="tel"]:focus, 
.ui.form input[type="time"]:focus, 
.ui.form input[type="text"]:focus, 
.ui.form input[type="file"]:focus, 
.ui.form input[type="url"]:focus {
    color: var(--smui-site-input-color, @inputColor);
}