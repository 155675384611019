.transfers-header-segment {
  margin-top: 15px !important;
  height: 78px !important;
  padding-top: 14px !important;
  display: flex;
}

.transfers-segment-icon {
  padding-top: 5px;
  padding-right: 1em;
  margin-top: 6px;
}

.transfers-header-action-button {
  float: right;
  margin-top: -48px !important;
}

.transfer-button {
  color: black;
}

.transfer-card {
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.transfer-segment {
  margin-top: 16px;
  padding-left: 15px;
  padding-right: 15px;
}

.transfer-placeholder {
  font-weight: 100;
  text-align: center;
  margin-top: 50px !important;
}

.transferlist-size {
  width: 150px;
  min-width: 150px;
}

.transferlist-filename {
  word-wrap: break-word !important;
}

.transfers-header-buttons {
  display: inline;
  margin-left: auto;
}
